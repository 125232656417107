<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <!-- badc sale center nominee information -->
        <body-card>
          <template v-slot:headerTitle>
            <h4 class="card-title font-weight-bold">{{ $t('teaGarden.badc_nominee_information') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider name="Name (En)" vid="badc_name_en" rules="required">
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="badc_name_en">
                    <template v-slot:label>
                      {{ $t('globalTrans.name') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="badc_name_en"
                      v-model="data.nominees[0].name_en"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider name="Name (Bn)" vid="badc_name_bn" rules="required">
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="badc_name_bn">
                    <template v-slot:label>
                      {{ $t('globalTrans.name') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="badc_name_bn"
                      v-model="data.nominees[0].name_bn"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider name="Designation (En)" vid="badc_designation_en" rules="required">
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="badc_designation_en">
                    <template v-slot:label>
                      {{ $t('globalTrans.designation') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="badc_designation_en"
                      v-model="data.nominees[0].designation_en"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider name="Designation (Bn)" vid="badc_designation_bn" rules="required">
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="badc_designation_bn">
                    <template v-slot:label>
                      {{ $t('globalTrans.designation') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="badc_designation_bn"
                      v-model="data.nominees[0].designation_bn"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider name="Mobile No." vid="badc_mobile" rules="required|min:11|max:11">
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="badc_mobile">
                    <template v-slot:label>
                      {{ $t('globalTrans.mobile') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      @keypress="isNumber"
                      type="number"
                      id="badc_mobile"
                      v-model="data.nominees[0].mobile"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider name="NID" vid="badc_nid" rules="required|min:10|max:17">
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="badc_nid">
                    <template v-slot:label>
                      {{ $t('globalTrans.nid') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                    @keypress="isNumber"
                      id="badc_nid"
                      type="number"
                      v-model="data.nominees[0].nid"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <!-- photo -->
              <b-col sm="6">
                <ValidationProvider name="Photo" vid="photo" rules="required">
                    <b-form-group slot-scope="{ valid, errors }" label-for="badc_photo">
                        <template v-slot:label>
                            {{ $t('teaGarden.photo') }} <span class="text-danger">*</span>
                        </template>
                        <div class="d-flex">
                            <b-form-file
                            accept="image/png, image/gif, image/jpeg"
                            id="badc_photo"
                            v-model="data.nominees[0].photo"
                            @change="onChange1($event)"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('globalTrans.attachment_placeholder')"
                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                        ></b-form-file>
                        <a target="_blank" style = "margin-left: 8px;" v-if="data.nominees[0].photo && checkBase64(data.nominees[0].photo) === false" :href="teaGardenServiceBaseUrl + 'storage' + data.nominees[0].photo" class="p-1" size="sm">
                            <img width="50" :src="teaGardenServiceBaseUrl  + 'storage' + data.nominees[0].photo" alt="">
                            </a>
                        </div>
                        <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                        </div>
                    </b-form-group>
                </ValidationProvider>
              </b-col>
              <!-- signature -->
              <b-col sm="6">
                <ValidationProvider name="Signature" vid="signature" rules="required">
                    <b-form-group slot-scope="{ valid, errors }" label-for="badc_signature">
                        <template v-slot:label>
                            {{ $t('teaGarden.signature') }} <span class="text-danger">*</span>
                        </template>
                        <div class="d-flex">
                            <b-form-file
                            accept="image/png, image/gif, image/jpeg"
                            id="badc_signature"
                            v-model="data.nominees[0].signature"
                            @change="onChange2($event)"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('globalTrans.attachment_placeholder')"
                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                        ></b-form-file>
                        <a target="_blank" style = "margin-left: 8px;" v-if="data.nominees[0].signature && checkBase64(data.nominees[0].signature) === false" :href="teaGardenServiceBaseUrl + 'storage' + data.nominees[0].signature" class="p-1" size="sm">
                            <img width="50" :src="teaGardenServiceBaseUrl  + 'storage' + data.nominees[0].signature" alt="">
                        </a>
                        </div>
                        <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                        </div>
                    </b-form-group>
                </ValidationProvider>
              </b-col>
              <!-- dd/pay order attachment  -->
              <b-col sm="12">
                  <div class="group-form-card">
                      <b-card>
                         <h6 class="mb-2"><b>{{$t('teaGarden.dd_pay_order_copy_info')}}</b></h6>
                          <b-form-group>
                              <b-col sm="12">
                                  <table class="table table-sm table-bordered section-tree-view-table">
                                      <tbody>
                                          <slot v-for="(item, index) in data.nominees[0].dd_details">
                                            <tr :key="'badc-' + index">
                                              <td class="text-center font-weight-bold">
                                                  {{getFertilizerName(item.fertilizer_id)}}
                                              </td>
                                            </tr>
                                              <tr :key="'badc' + index">
                                              <td>
                                                <b-row>
                                                  <b-col sm="6" class="mt-2">
                                                  <ValidationProvider name="Pay Order" :vid="`badc_pay_order_no-${index}`" :rules="{ required: true }">
                                                  <b-form-group :label-for="`badc_pay_order_no-${index}`" slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                      :id="'badc_pay_order_no' + index"
                                                      :placeholder="$t('teaGarden.pay_order_no')"
                                                      v-model="item.pay_order_no"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback text-left">
                                                      {{ errors[0] }}
                                                    </div>
                                                  </b-form-group>
                                                </ValidationProvider>
                                                </b-col>
                                                <b-col sm="6" class="mt-2">
                                                  <ValidationProvider name="Pay Order Amount" :vid="`badc_pay_order_amount-${index}`" :rules="{ required: true }">
                                                  <b-form-group :label-for="`badc_pay_order_amount-${index}`" slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                      type="number"
                                                      @keypress="isNumber"
                                                      :id="'badc_pay_order_amount' + index"
                                                      :placeholder="$t('teaGarden.pay_order_amount')"
                                                      v-model="item.pay_order_amount"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback text-left">
                                                      {{ errors[0] }}
                                                    </div>
                                                  </b-form-group>
                                                </ValidationProvider>
                                                </b-col>
                                              </b-row>
                                              <b-row>
                                                <b-col sm="6">
                                                  <ValidationProvider name="Pay Order Date" :vid="`badc_pay_order_date-${index}`" :rules="{ required: true }">
                                                  <b-form-group :label-for="`badc_pay_order_date-${index}`" slot-scope="{ valid, errors }">
                                                       <date-picker
                                                          :id="'badc_pay_order_date' + index"
                                                          v-model="item.pay_order_date"
                                                          class="form-control"
                                                          :placeholder="$t('globalTrans.select_date')"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                          :class="errors[0] ? 'is-invalid' : ''"
                                                          :locale="currentLocale"
                                                        >
                                                        </date-picker>
                                                    <div class="invalid-feedback text-left">
                                                      {{ errors[0] }}
                                                    </div>
                                                  </b-form-group>
                                                </ValidationProvider>
                                                </b-col>
                                                <b-col sm="6">
                                                  <ValidationProvider name="Bank Name" :vid="`badc_bank_id-${index}`" :rules="{ required: true , min_value: 1}">
                                                  <b-form-group :label-for="`badc_bank_id-${index}`" slot-scope="{ valid, errors }">
                                                        <b-form-select
                                                          plain
                                                          @change="getBranchListBadc(item.bank_id, index)"
                                                          :id="'badc_bank_id' + index"
                                                          v-model="item.bank_id"
                                                          :options="bankList"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                          <b-form-select-option :value="0">{{$t('globalTrans.select_bank')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                    <div class="invalid-feedback text-left">
                                                      {{ errors[0] }}
                                                    </div>
                                                  </b-form-group>
                                                </ValidationProvider>
                                                </b-col>
                                              </b-row>
                                              <b-row>
                                                <b-col sm="6">
                                                  <ValidationProvider name="Branch Name" :vid="`badc_branch_id-${index}`" :rules="{ required: true , min_value: 1}">
                                                  <b-form-group :label-for="`badc_branch_id-${index}`" slot-scope="{ valid, errors }">
                                                        <b-form-select
                                                          plain
                                                          :id="'badc_branch_id' + index"
                                                          v-model="item.branch_id"
                                                          :options="branchListBadc[index]"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                          <b-form-select-option :value="0">{{$t('globalTrans.select_branch')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                    <div class="invalid-feedback text-left">
                                                      {{ errors[0] }}
                                                    </div>
                                                  </b-form-group>
                                                </ValidationProvider>
                                                </b-col>
                                                <b-col sm="6">
                                                <ValidationProvider name="DD / Pay Order" :vid="`dd_pay_order_copy_badc-${index}`" :rules="{ required: true }">
                                                  <b-form-group :label-for="`dd_pay_order_copy_badc-${index}`" slot-scope="{ valid, errors }">
                                                      <div class="d-flex">
                                                      <b-form-file
                                                      accept=".pdf, .docx, .doc"
                                                      :id="'dd_pay_order_copy_badc' + index"
                                                      v-model="item.dd_pay_order_copy"
                                                      @change="addMoreFileOnChangeBADC($event, index)"
                                                      :placeholder="$t('teaGarden.attachment_dd_placeholder')"
                                                      :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-file>
                                                      <a target="_blank" style = "margin-left: 8px;" v-if="item.dd_pay_order_copy && checkBase64(item.dd_pay_order_copy) === false" :href="teaGardenServiceBaseUrl  + item.dd_pay_order_copy" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                                      </div>
                                                      <div class="invalid-feedback text-left">
                                                      {{ errors[0] }}
                                                    </div>
                                                  </b-form-group>
                                                </ValidationProvider>
                                                </b-col>
                                              </b-row>
                                              </td>
                                              </tr>
                                          </slot>
                                      </tbody>
                                  </table>
                              </b-col>
                          </b-form-group>
                      </b-card>
                  </div>
              </b-col>
            </b-row>
          </template>
        </body-card>
        <!-- same as -->
         <b-form-checkbox
          class="mb-2"
          @change="smaeAsNominee()"
          id="same_as_nominee"
          v-model="same_as_nominee"
          name="same_as_nominee"
          :value="true"
          :unchecked-value="not_accepted"
        >
          <b>{{$t('globalTrans.same')}}</b>
        </b-form-checkbox>
        <!-- bcic sale center nominee information -->
        <body-card>
          <template v-slot:headerTitle>
            <h4 class="card-title font-weight-bold">{{ $t('teaGarden.bcic_nominee_information') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider name="Name (En)" vid="bcic_name_en" rules="required">
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="bcic_name_en">
                    <template v-slot:label>
                      {{ $t('globalTrans.name') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="bcic_name_en"
                      v-model="data.nominees[1].name_en"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider name="Name (Bn)" vid="bcic_name_bn" rules="required">
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="bcic_name_bn">
                    <template v-slot:label>
                      {{ $t('globalTrans.name') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="bcic_name_bn"
                      v-model="data.nominees[1].name_bn"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider name="Designation (En)" vid="bcic_designation_en" rules="required">
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="bcic_designation_en">
                    <template v-slot:label>
                      {{ $t('globalTrans.designation') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="bcic_designation_en"
                      v-model="data.nominees[1].designation_en"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider name="Designation (Bn)" vid="bcic_designation_bn" rules="required">
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="bcic_designation_bn">
                    <template v-slot:label>
                      {{ $t('globalTrans.designation') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="bcic_designation_bn"
                      v-model="data.nominees[1].designation_bn"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider name="Mobile No." vid="bcic_mobile" rules="required|min:11|max:11">
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="bcic_mobile">
                    <template v-slot:label>
                      {{ $t('globalTrans.mobile') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      @keypress="isNumber"
                      type="number"
                      id="bcic_mobile"
                      v-model="data.nominees[1].mobile"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider name="NID" vid="bcic_nid" rules="required|min:10|max:17">
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="bcic_nid">
                    <template v-slot:label>
                      {{ $t('globalTrans.nid') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                    @keypress="isNumber"
                      id="bcic_nid"
                      type="number"
                      v-model="data.nominees[1].nid"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <!-- photo -->
              <b-col sm="6">
                <ValidationProvider name="Photo" vid="bcic_photo" rules="required">
                    <b-form-group slot-scope="{ valid, errors }" label-for="bcic_photo">
                        <template v-slot:label>
                            {{ $t('teaGarden.photo') }} <span class="text-danger">*</span>
                        </template>
                        <div class="d-flex">
                            <b-form-file
                            accept="image/png, image/gif, image/jpeg"
                            id="bcic_photo"
                            v-model="data.nominees[1].photo"
                            @change="onChange3($event)"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('globalTrans.attachment_placeholder')"
                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                        ></b-form-file>
                        <a target="_blank" style = "margin-left: 8px;" v-if="data.nominees[1].photo && checkBase64(data.nominees[1].photo) === false" :href="teaGardenServiceBaseUrl + 'storage' + data.nominees[1].photo" class="p-1" size="sm">
                            <img width="50" :src="teaGardenServiceBaseUrl  + 'storage' + data.nominees[1].photo" alt="">
                            </a>
                        </div>
                        <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                        </div>
                    </b-form-group>
                </ValidationProvider>
              </b-col>
              <!-- signature -->
              <b-col sm="6">
                <ValidationProvider name="Signature" vid="bcic_signature" rules="required">
                    <b-form-group slot-scope="{ valid, errors }" label-for="bcic_signature">
                        <template v-slot:label>
                            {{ $t('teaGarden.signature') }} <span class="text-danger">*</span>
                        </template>
                        <div class="d-flex">
                            <b-form-file
                            accept="image/png, image/gif, image/jpeg"
                            id="bcic_signature"
                            v-model="data.nominees[1].signature"
                            @change="onChange4($event)"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('globalTrans.attachment_placeholder')"
                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                        ></b-form-file>
                        <a target="_blank" style = "margin-left: 8px;" v-if="data.nominees[1].signature && checkBase64(data.nominees[1].signature) === false" :href="teaGardenServiceBaseUrl + 'storage' + data.nominees[1].signature" class="p-1" size="sm">
                            <img width="50" :src="teaGardenServiceBaseUrl  + 'storage' + data.nominees[1].signature" alt="">
                        </a>
                        </div>
                        <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                        </div>
                    </b-form-group>
                </ValidationProvider>
              </b-col>
              <!-- dd/pay order attachment  -->
              <b-col sm="12">
                  <div class="group-form-card">
                      <b-card>
                        <h6 class="mb-2"><b>{{$t('teaGarden.dd_pay_order_copy_info')}}</b></h6>
                          <b-form-group>
                              <b-col sm="12">
                                  <table class="table table-sm table-bordered section-tree-view-table">
                                      <tbody>
                                          <slot v-for="(item, index) in data.nominees[1].dd_details">
                                            <tr :key="'bcic-' + index">
                                               <td class="text-center font-weight-bold">
                                                  {{getFertilizerName(item.fertilizer_id)}}
                                              </td>
                                            </tr>
                                              <tr :key="'bcic' + index">
                                              <td>
                                              <b-row>
                                                <b-col sm="6" class="mt-2">
                                                  <ValidationProvider name="Pay Order" :vid="`bcic_pay_order_no-${index}`" :rules="{ required: true }">
                                                  <b-form-group :label-for="`bcic_pay_order_no-${index}`" slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                      :id="'bcic_pay_order_no' + index"
                                                      :placeholder="$t('teaGarden.pay_order_no')"
                                                      v-model="item.pay_order_no"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback text-left">
                                                      {{ errors[0] }}
                                                    </div>
                                                  </b-form-group>
                                                </ValidationProvider>
                                                </b-col>
                                                <b-col sm="6" class="mt-2">
                                                  <ValidationProvider name="Pay Order Amount" :vid="`bcic_pay_order_amount-${index}`" :rules="{ required: true }">
                                                  <b-form-group :label-for="`bcic_pay_order_amount-${index}`" slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                      type="number"
                                                      @keypress="isNumber"
                                                      :id="'bcic_pay_order_amount' + index"
                                                      :placeholder="$t('teaGarden.pay_order_amount')"
                                                      v-model="item.pay_order_amount"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback text-left">
                                                      {{ errors[0] }}
                                                    </div>
                                                  </b-form-group>
                                                </ValidationProvider>
                                                </b-col>
                                              </b-row>
                                              <b-row>
                                                <b-col sm="6">
                                                  <ValidationProvider name="Pay Order Date" :vid="`bcic_pay_order_date-${index}`" :rules="{ required: true }">
                                                  <b-form-group :label-for="`bcic_pay_order_date-${index}`" slot-scope="{ valid, errors }">
                                                       <date-picker
                                                          :id="'bcic_pay_order_date' + index"
                                                          v-model="item.pay_order_date"
                                                          class="form-control"
                                                          :placeholder="$t('globalTrans.select_date')"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                          :class="errors[0] ? 'is-invalid' : ''"
                                                          :locale="currentLocale"
                                                        >
                                                        </date-picker>
                                                    <div class="invalid-feedback text-left">
                                                      {{ errors[0] }}
                                                    </div>
                                                  </b-form-group>
                                                </ValidationProvider>
                                                </b-col>
                                                <b-col sm="6">
                                                  <ValidationProvider name="Bank Name" :vid="`bcic_bank_id-${index}`" :rules="{ required: true , min_value: 1}">
                                                  <b-form-group :label-for="`bcic_bank_id-${index}`" slot-scope="{ valid, errors }">
                                                        <b-form-select
                                                          plain
                                                          @change="getBranchListBcic(item.bank_id)"
                                                          :id="'bcic_bank_id' + index"
                                                          v-model="item.bank_id"
                                                          :options="bankList"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                          <b-form-select-option :value="0">{{$t('globalTrans.select_bank')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                    <div class="invalid-feedback text-left">
                                                      {{ errors[0] }}
                                                    </div>
                                                  </b-form-group>
                                                </ValidationProvider>
                                                </b-col>
                                              </b-row>
                                              <b-row>
                                                  <b-col sm="6">
                                                  <ValidationProvider name="Branch Name" :vid="`bcic_branch_id-${index}`" :rules="{ required: true , min_value: 1}">
                                                  <b-form-group :label-for="`bcic_branch_id-${index}`" slot-scope="{ valid, errors }">
                                                        <b-form-select
                                                          plain
                                                          :id="'bcic_branch_id' + index"
                                                          v-model="item.branch_id"
                                                          :options="branchListBcic"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                          <b-form-select-option :value="0">{{$t('globalTrans.select_branch')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                    <div class="invalid-feedback text-left">
                                                      {{ errors[0] }}
                                                    </div>
                                                  </b-form-group>
                                                </ValidationProvider>
                                                </b-col>
                                                 <b-col sm="6">
                                                  <ValidationProvider name="DD / Pay Order" :vid="`dd_pay_order_copy_bcic-${index}`" :rules="{ required: true }">
                                                  <b-form-group :label-for="`dd_pay_order_copy_bcic-${index}`" slot-scope="{ valid, errors }">
                                                      <div class="d-flex">
                                                      <b-form-file
                                                      accept=".pdf, .docx, .doc"
                                                      :id="'dd_pay_order_copy_bcic' + index"
                                                      v-model="item.dd_pay_order_copy"
                                                      @change="addMoreFileOnChangeBCIC($event, index)"
                                                      :placeholder="$t('teaGarden.attachment_dd_placeholder')"
                                                      :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-file>
                                                      <a target="_blank" style = "margin-left: 8px;" v-if="item.dd_pay_order_copy && checkBase64(item.dd_pay_order_copy) === false" :href="teaGardenServiceBaseUrl  + item.dd_pay_order_copy" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                                      </div>
                                                      <div class="invalid-feedback text-left">
                                                      {{ errors[0] }}
                                                    </div>
                                                  </b-form-group>
                                                </ValidationProvider>
                                                </b-col>
                                              </b-row>
                                              </td>
                                              </tr>
                                          </slot>
                                      </tbody>
                                  </table>
                              </b-col>
                          </b-form-group>
                      </b-card>
                  </div>
              </b-col>
            </b-row>
          </template>
        </body-card>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('nominee-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { fertilizerRequestNomineeStore } from '../../../api/routes'
import { helpers } from '@/utils/helper-functions'
export default {
  name: 'NomineeForm',
  props: ['fertilizer_reqst_app_id', 'status', 'app_details'],
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      valid: null,
      saveBtnName: this.status === 8 ? this.$t('globalTrans.save') : this.$t('globalTrans.update'),
      errors: [],
      // nominees[0] => badc, nominees[1] => bcic
      data: {
        fertilizer_reqst_app_id: 0,
        nominees: [
          {
            sale_center_id: 0,
            photo: '',
            signature: '',
            dd_details: []
          },
          {
            sale_center_id: 0,
            photo: '',
            signature: '',
            dd_details: []
          }
        ]
      },
      same_as_nominee: false,
      branchListBcic: [],
      branchListBadc: {}
    }
  },
  created () {
    if (this.fertilizer_reqst_app_id) {
      const tmp = this.getData()
      this.data.fertilizer_reqst_app_id = tmp.id
      this.data.nominees[0].sale_center_id = tmp.proposed_badc_sale_center_id
      this.data.nominees[1].sale_center_id = tmp.proposed_bcic_sale_center_id
      if (tmp.nominees.length > 1) {
        this.data = tmp
        this.data.fertilizer_reqst_app_id = tmp.id
        this.data.nominees[0].dd_details.forEach((item, index) => {
          this.getBranchListBadc(item.bank_id, index)
        })
        this.getBranchListBcic(this.data.nominees[1].dd_details[0].bank_id)
      } else {
        this.app_details.proposals.map(item => {
          const fertilizerName = this.$store.state.TeaGardenService.commonObj.masterFertilizerList.find(findItem => findItem.value === item.fertilizer_id).text_en
          if (fertilizerName && fertilizerName.toLowerCase() !== 'urea') {
            const obj = {
              dd_pay_order_copy: '',
              fertilizer_id: item.fertilizer_id,
              pay_order_no: '',
              bank_id: 0,
              branch_id: 0
            }
            this.data.nominees[0].dd_details.push(obj)
          } else {
            const obj = {
              dd_pay_order_copy: '',
              fertilizer_id: item.fertilizer_id,
              pay_order_no: '',
              bank_id: 0,
              branch_id: 0
            }
            this.data.nominees[1].dd_details.push(obj)
          }
        })
      }
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    currentLocale () {
      return this.$i18n.locale
    },
     bankList: function () {
      return this.$store.state.CommonService.commonObj.bankList.filter(item => item.status === 1)
    }
  },
  methods: {
    async saveUpdate () {
      var check = await this.$refs.form.validate()
      if (check) {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }
        result = await RestApi.postData(teaGardenServiceBaseUrl, fertilizerRequestNomineeStore, this.data)
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)

        if (result.success) {
          this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.status === 8 ? this.$t('globalTrans.save_msg') : this.$t('globalTrans.update_msg'),
            color: '#D6E09B'
          })
          this.$bvModal.hide('nominee-form')
        } else {
          this.$refs.form.setErrors(result.errors)
        }
      }
    },
    isNumber (evt) {
        helpers.isNumber(evt)
    },
    getData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.fertilizer_reqst_app_id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    checkBase64 (string) {
        var result = ''
        result = string.match('data:')
        if (result) {
            return true
        } else {
            return false
        }
    },
    onChange1 (event) {
        const input = event.target
        if (input.files && input.files[0]) {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.data.nominees[0].photo = e.target.result
            }
            reader.readAsDataURL(input.files[0])
        } else {
            this.data.nominees[0].photo = ''
        }
    },
    onChange2 (event) {
    const input = event.target
      if (input.files && input.files[0]) {
          const reader = new FileReader()
          reader.onload = (e) => {
              this.data.nominees[0].signature = e.target.result
          }
          reader.readAsDataURL(input.files[0])
      } else {
          this.data.nominees[0].signature = ''
      }
    },
    onChange3 (event) {
    const input = event.target
      if (input.files && input.files[0]) {
          const reader = new FileReader()
          reader.onload = (e) => {
              this.data.nominees[1].photo = e.target.result
          }
          reader.readAsDataURL(input.files[0])
      } else {
          this.data.nominees[1].photo = ''
      }
    },
    onChange4 (event) {
    const input = event.target
      if (input.files && input.files[0]) {
          const reader = new FileReader()
          reader.onload = (e) => {
              this.data.nominees[1].signature = e.target.result
          }
          reader.readAsDataURL(input.files[0])
      } else {
          this.data.nominees[1].signature = ''
      }
    },
    addMoreFileOnChangeBADC (event, index) {
      const input = event.target
      if (input.files && input.files[0]) {
          const reader = new FileReader()
          reader.onload = (e) => {
              this.data.nominees[0].dd_details[index].dd_pay_order_copy = e.target.result
          }
          reader.readAsDataURL(input.files[0])
      } else {
         this.data.nominees[0].dd_details[index].dd_pay_order_copy = ''
      }
    },
    addMoreFileOnChangeBCIC (event, index) {
      const input = event.target
      if (input.files && input.files[0]) {
          const reader = new FileReader()
          reader.onload = (e) => {
              this.data.nominees[1].dd_details[index].dd_pay_order_copy = e.target.result
          }
          reader.readAsDataURL(input.files[0])
      } else {
         this.data.nominees[1].dd_details[index].dd_pay_order_copy = ''
      }
    },
    smaeAsNominee () {
      if (this.same_as_nominee) {
        this.$set(this.data.nominees[1], 'name_en', this.data.nominees[0].name_en)
        this.$set(this.data.nominees[1], 'name_bn', this.data.nominees[0].name_bn)
        this.$set(this.data.nominees[1], 'designation_en', this.data.nominees[0].designation_en)
        this.$set(this.data.nominees[1], 'designation_bn', this.data.nominees[0].designation_bn)
        this.$set(this.data.nominees[1], 'mobile', this.data.nominees[0].mobile)
        this.$set(this.data.nominees[1], 'nid', this.data.nominees[0].nid)
        this.$set(this.data.nominees[1], 'photo', this.data.nominees[0].photo)
        this.$set(this.data.nominees[1], 'signature', this.data.nominees[0].signature)
      } else {
        this.$set(this.data.nominees[1], 'name_en', '')
        this.$set(this.data.nominees[1], 'name_bn', '')
        this.$set(this.data.nominees[1], 'designation_en', '')
        this.$set(this.data.nominees[1], 'designation_bn', '')
        this.$set(this.data.nominees[1], 'mobile', '')
        this.$set(this.data.nominees[1], 'nid', '')
        this.$set(this.data.nominees[1], 'photo', '')
        this.$set(this.data.nominees[1], 'signature', '')
      }
    },
    getFertilizerName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterFertilizerList.find(item => item.value === id)
      if (obj) {
        return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getBranchListBcic (bankId) {
      this.branchListBcic = this.$store.state.CommonService.commonObj.branchList.filter(item => (item.bank_id === bankId))
    },
    getBranchListBadc (bankId, fertilizerIndex) {
      this.branchListBadc[fertilizerIndex] = this.$store.state.CommonService.commonObj.branchList.filter(item => (item.bank_id === bankId))
    }
}
}
</script>
